<template>
  <Gallery :options="{}">
    <div class="item">
      <a
        v-for="(imgItem, idx) in period.images"
        :key="`img-${idx}`"
        class="data-fancybox__link"
        :href="img(String(imgItem.imageId), { lazyLoading: true})"
        :data-fancybox="`flat-${index}`"
      >
      </a>
      <div class="adaptive-img image-wrapper">
        <NuxtImg
          :src="String(period.preview)"
          loading="lazy"
          alt="alt"
          width="1000"
          height="1000"
          fit="contain"
          placeholder
        />
      </div>
      <div class="description">
        {{ period.title }}
        <span> / {{ period.images.length }} фото</span>
      </div>
    </div>
  </Gallery>
</template>

<script setup lang="ts">
import type { ProgressPeriod } from '~/cms/blocks/ConstructionProgressBlock.vue';

const { period } = defineProps<{
  period: ProgressPeriod,
  index: number
}>();

const img = useImage();
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.item {
  padding: 12px 12px 20px;
  border-radius: 20px;
  border: 1px solid rgb(226 221 233 / 50%);
  background: var(--white);
  box-shadow: 0 0 0 0 rgb(0 0 0 / 3%), 0 2.992px 5.985px 0 rgb(0 0 0 / 3%), 0 10.474px 10.474px 0 rgb(0 0 0 / 3%), 0 23.941px 13.466px 0 rgb(0 0 0 / 2%), 0 41.896px 16.459px 0 rgb(0 0 0 / 0%), 0 65.838px 17.958px 0 rgb(0 0 0 / 0%);
  position: relative;

  & > a {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
  }
}

.image-wrapper {
  height: 276px;
  margin-bottom: 20px;

  & > img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
}

.description {
  color: var(--primary);
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: -0.48px;

  & > span {
    color: var(--primary-40);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    letter-spacing: -0.96px;
  }
}

@include media('sm') {
  .image-wrapper {
    height: 185px;
  }

  .description {
    font-size: 16px;

    & > span {
      font-size: 16px;
    }
  }
}
</style>
